<template>
  <div>
    <section class="storeProducts--back">
      <div class="inner-wrapper">
        <div class="storeProducts">
          <div class="storeProducts__content">
            <div class="storeProducts__content--left">
              <div class="storeProducts__content__greet">
                <div class="storeProducts__content__greet__title">
                  <H2
                      :title="h2._1.title"
                      :color="h2._1.color"
                      :id="h2._1.id"
                  />
                </div>
                <div class="storeProducts__content__greet__text first">
                  <p class="product-details-title">{{ product.detailsTitle }}</p>
                  <p>{{ product.detailsSubtitle }}</p>
                </div>
                <div class="storeProducts__content__greet__text first">
                  <p v-html="product.description2"></p>
                </div>

              </div>
              <div class="storeProducts__content__purchase">
                <div class="storeProducts__content__purchase--wrp">
                  <div class="storeProducts__content__purchase__price">
                    <div class="storeProducts__content__purchase__price__title">
                      {{ cms.page.fgPrice }}
                    </div>
                    <div class="storeProducts__content__purchase__price__price">
                      {{ this.showFormattedPrice(product.price) }}
                    </div>
                  </div>
                  <div class="storeProducts__content__purchase__quantity">
                    <div class="storeProducts__content__purchase__quantity__title">{{ cms.page.fgQuantity }}</div>
                    <div class="storeProducts__content__purchase__quantity__input">
                      <input-number
                          v-model.number="localCart[product.specialProductId]"
                          :shouldBeFlexEnd="true"
                      />
                    </div>
                    <div class="storeProducts__content__purchase__quantity__price">
                      <span class="storeProducts__content__purchase__quantity__price__total">
                        {{ cms.page.fgTotal }}
                      </span>
                      <span class="storeProducts__content__purchase__quantity__price__price">
                        ${{ (localCart[product.specialProductId] * product.price).toFixed(2) }}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="storeProducts__content__purchase__note">
                  {{ cms.page.fgPleaseNote }}
                  <router-link :to="{ name: 'FAQs', hash: '#store', params: { openQuestion: 'return-policy' } }">
                    {{ cms.page.fgClickHere }}
                  </router-link>
                  {{ cms.page.fgToReadMoreInformation }}
                </div>
                <button
                    class="storeProducts__content__purchase__button btn btn-default"
                    @click="addToCart(product.specialProductId)"
                >
                  <!-- Working state -->
                  <span
                      v-if="product.specialProductId === cart.specialProductId && cart.isUpdating && !cart.errorDuringUpdate"
                  >
                  {{ cms.page.fgUpdating }}
                  <i class="fa-solid fa-spinner fa-spin margin-icon-left"></i>
                </span>

                  <!-- Work completed, but ERROR state (switched off by a JS Timeout function) &ndash;&gt;-->
                  <span v-else-if="product.specialProductId === cart.specialProductId && cart.errorDuringUpdate">
                  {{ cms.page.fgError }}
                  <i class="fa-solid fa-circle-exclamation fa-lg margin-icon-left"></i>
                </span>

                  <!-- Default state -->
                  <span v-else="product.specialProductId !== cart.specialProductId">{{ cms.page.fgAddToCart }}</span>

                </button>
              </div>
            </div>
            <div class="storeProducts__content--right">
              <div class="storeProducts__content__slider">
                <img
                    class="storeProducts__content__slider__main img-fluid"
                    :src="product.imagePath"
                    @click="showLightbox"
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="storeProducts__related">
      <div class="container-s">
        <div class="storeProducts__related__title">
          <H2 :title="h2._2.title" :color="h2._2.color" :id="h2._2.id"/>
        </div>

        <div class="storeProducts__related__items">
          <div v-for="relatedProduct in relatedProducts" class="storeProducts__related__items__item">
            <router-link :to="{ name: 'StoreProducts', params: { id: relatedProduct.specialProductId }}">
              <div class="storeProducts__related__items__item__img">
                <img :src="relatedProduct.imagePath"/>
              </div>
              <div class="storeProducts__related__items__item__title">
                {{ relatedProduct.title }}
              </div>
            </router-link>
          </div>
        </div>

      </div>
    </section>

    <vue-easy-lightbox
        :visible="visible"
        :imgs="product.imagePath"
        :index=0
        :scrollDisabled="true"
        :moveDisabled="true"
        @hide="hideLightbox"
    ></vue-easy-lightbox>
  </div>
</template>
<script>
import InputNumber from "../components/inputNumber"

import VueEasyLightbox from "vue-easy-lightbox"
import TitlePage from "../components/TitlePage"
import H2 from "../components/H2"
import {mapStores} from 'pinia'
import {useAuthStore} from "@/stores/authStore"
import {useCmsStore} from "@/stores/cmsStore"
import {useDesignStore} from "@/stores/designStore"
import {useProductStore} from "@/stores/productStore"
import {storeMixin} from "@/mixins/storeMixin"

export default {
  name: 'StoreProducts',
  mixins: [storeMixin],
  components: {
    TitlePage,
    InputNumber,
    VueEasyLightbox,
    H2,
  },
  data() {
    return {
      cart: {
        errorDuringUpdate: false,
        isUpdating: false,
        recentlyUpdated: false,
        specialProductId: '',
      },
      cms: {
        loaded: false,  // set to 'true' when CMS content has been returned from API
        options: {
          Common: true,
          Components: ["h2", "input_number", "title_page"],
          LanguageCode: "",
          PageName: "store_products",
          MinistryUpdates: false
        },
        page: {},
      },
      quantity: 0,
      product: {
        description: '',
        description2: '',
        donationAmount: '',
        imagePath: '',
        infoCaption: '',
        merchCode: '',
        occasionDescription: '',
        price: '',
        productId: '',
        productType: '',
        qtyDiscount: '',
        shoppingCartTitle: '',
        specialProductGroupId: '',
        specialProductGroupIdParent: '',
        specialProductId: '',
        style: '',
        title: '',
      },
      h2: {
        _1: {
          title: " Product Details",
          id: "t1",
          color: "color-black",
        },
        _2: {
          title: " Related Products",
          id: "t2",
          color: "color-black",
        },
      },
      localCart: {},
      relatedProducts: [],
      visible: false,
    };
  },
  computed: {
    ...mapStores(useAuthStore, useCmsStore, useDesignStore, useProductStore),
  },
  created() {
    this.createdMethodCalls()
  },
  mounted() {
    this.setTitlePage(this.product.title)
    this.designStore.setIsLogo(true);
  },
  methods: {
    async createdMethodCalls() {
      if (!this.$route.params.id) {
        await this.$router.push({name: 'Store'})
      }
      this.localCart = {}
      this.resetLocalCartQuantities(false)
      this.setContentLoaded(false)

      this.product = this.productStore.getProductById(this.$route.params.id)
      this.relatedProducts = this.productStore.getRelatedProducts(this.$route.params.id)
      await this.fetchCmsData()
    },
    async fetchCmsData() {
      try {
        await this.cmsStore.getPageAndOrCommonAndOrComponents(this.cms.options)
            .then(results => {
              if (results) {
                this.cms.page = results
              } else return false

              this.setComponents()
              this.setContentLoaded(true)
              return true;
            })
      } catch (e) {
        console.error(e)
      }
    },
    setComponents() {
      this.h2._1.title = this.cms.page.fgProductDetails
      this.h2._2.title = this.cms.page.fgRelatedProducts
    },
    setContentLoaded(loadedStatus) {
      this.cms.loaded = loadedStatus
    },
    setTitlePage(subTitle) {
      this.designStore.setTitlePage({
        title: 'Store Products',
        sub_title: subTitle,
        img_class: 'bg-storeProduct',
        btn_history: true,
      });
    },
    showLightbox() {
      this.visible = true;
    },
    hideLightbox() {
      this.visible = false;
    },

  },
  watch: {
    $route(to, from) {
      if (to && to.fullPath === "/store") {
        this.$router.push({name: 'Store'})
      } else if (to && to.fullPath.includes("/store/")) {
        // Friend clicked one of the 'related products', so we need to re-trigger the 'created' lifecycle hook
        this.createdMethodCalls()
        this.setTitlePage(this.product.title)
      }
    }
  },
}
</script>


<style scoped lang="scss">
@import "../assets/scss/abstracts/_color.scss";
@import "../assets/scss/abstracts/_variables.scss";

.h-100 {
  height: 100%;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.storeProducts--back .inner-wrapper {
  width: 1100px;
  margin: 0 auto;
  max-width: 100%;
  padding: 50px;

  @media screen and (max-width: $sm) {
    padding: 50px 25px;
  }
}

.storeProducts {
  padding-bottom: 78px;

  h2 {
    color: #0c2e3c;
    @media screen and (max-width: 1180px) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .storeProducts__content__greet__text {
    color: #0c2e3c;
  }

  .product-details-title {
    font-size: 18px;
    margin-bottom: 8px;
  }

  &__content {
    display: flex;
    justify-content: space-between;
    padding-top: 46px;
    padding-bottom: 36px;

    &__greet {
      grid-column-start: 1;
      grid-column-end: 3;

      &__title {
        font-weight: 500;

        @media screen and (max-width: 768px) {
          font-size: 25px;
          line-height: 36px;
          font-weight: 400;
          margin-bottom: 25px;
        }

        &:after {
          @media screen and (max-width: 790px) {
            margin-top: 15px;
            height: 7px;
          }
        }
      }

      &__text {
        font-size: 18px;
        line-height: 33px;
        margin-bottom: 32px;

        &__link {
          color: #a7191e;
          font-weight: 700;
        }

        &.first {
          @media screen and (max-width: 790px) {
            margin-bottom: 33px;
          }
        }

        @media screen and (max-width: 790px) {
          font-size: 15px;
          line-height: 30px;
          font-weight: 400;
          margin-bottom: 23px;
        }
      }

      &__buttons {
        &__button {
          cursor: pointer;
          font-weight: 400;

          &:first-child {
            margin-right: 50px;

            @media screen and (max-width: 790px) {
              margin-right: 10px;
            }
          }

          &--blue {
            color: #fff;

            &:before {
              background-color: #0c2e3c;
            }

            @media screen and (max-width: 790px) {
              font-size: 14px;
              line-height: 45px;
              font-weight: 400;
              min-width: 141px;
            }
          }

          @media screen and (max-width: 790px) {
            font-size: 14px;
            line-height: 45px;
            font-weight: 400;
            min-width: 141px;
          }
        }

        @media screen and (max-width: 790px) {
          display: flex;
          justify-content: space-around;
          flex-wrap: wrap;
        }
      }

      @media screen and (max-width: 790px) {
        grid-column-end: 2;
        margin-bottom: 42px;
      }
    }

    &__purchase {
      position: relative;
      max-width: 537px;
      width: 100%;
      background-color: #28677d;
      padding: 45px 34px 69px 34px;
      color: #fff;

      &__price {
        &__title {
          font-size: 18px;
          line-height: 33px;
          font-weight: 700;
          margin-bottom: 7px;

          @media screen and (max-width: 576px) {
            font-size: 15px;
            line-height: 30px;
          }
        }

        &__price {
          font-size: 18px;
          line-height: 33px;
          font-weight: 400;
        }
      }

      &__quantity {
        text-align: right;

        &__title {
          font-size: 18px;
          line-height: 33px;
          font-weight: 700;
          margin-bottom: 14px;

          @media screen and (max-width: 576px) {
            font-size: 15px;
            line-height: 30px;
          }
        }

        &__input {
          margin-bottom: 28px;

          @media screen and (max-width: 576px) {
            margin-bottom: 23px;
          }
        }

        &__price {
          text-align: right;

          &__total {
            font-size: 18px;
            line-height: 33px;
            font-weight: 700;

            @media screen and (max-width: 576px) {
              font-size: 15px;
              line-height: 30px;
            }
          }

          &__price {
            font-size: 18px;
            line-height: 33px;
            font-weight: 400;
            margin-left: 57px;

            @media screen and (max-width: 576px) {
              font-size: 15px;
              line-height: 30px;
              margin-left: 25px;
            }
          }
        }

        @media screen and (max-width: 576px) {
          max-width: 120px;
        }
      }

      &__note {
        a {
          font-weight: 700;
          color: $yellow-darker;
        }

        @media screen and (max-width: 576px) {
          font-size: 14px;
          line-height: 25px;
        }
      }

      &--wrp {
        display: flex;
        justify-content: space-between;
        margin-bottom: 44px;

        @media screen and (max-width: 576px) {
          margin-bottom: 25px;
        }
      }

      &__button {
        position: absolute;
        left: 50%;
        top: 100%;
        transform: translateX(-50%) translateY(-50%);

        @media screen and (max-width: 576px) {
          font-size: 14px;
          line-height: 45px;
          font-weight: 400;
          min-width: 141px;
        }
      }

      @media screen and (max-width: 576px) {
        font-size: 15px;
        line-height: 30px;
        padding: 23px 30px 45px 30px;
      }
    }

    &--left {
      max-width: 537px;
    }

    &--right {
      max-width: 538px;

      @media screen and (max-width: 1180px) {
        margin-bottom: 14px;
      }
    }

    &__slider {
      &__main {
        cursor: pointer;
        position: relative;
        width: 100%;
        height: 100%;
        margin-bottom: 21px;
        box-shadow: 0 25px 25px -14px rgba(0, 0, 0, 0.3);
        transition: all .33s ease;
        transform: scale(1);

        &__zoom {
          position: absolute;
          right: 21.2px;
          bottom: 17.7px;
        }

        .splide__track {
          height: 100%;

          .splide__slide {
            position: relative;
            cursor: pointer;

            img {
              width: 100%;
              height: 100%;
              object-fit: contain;


              @media screen and (max-width: 576px) {
                object-fit: cover;
              }
            }
          }
        }

        &:hover {
          box-shadow: 0 15px 15px -4px rgba(0, 0, 0, 0.1);
          transform: scale(.98);
        }
      }

      &__thumbs {
        .splide__slide.is-active {
          border: 2px solid #0c2e3c !important;
          opacity: 1;
        }

        .splide__list {
          justify-content: center;
        }

        .splide__slide {
          opacity: 0.4;
        }
      }
    }

    @media screen and (max-width: 1180px) {
      padding-top: 0;
      flex-direction: column-reverse;
      align-items: center;
    }
    @media screen and (max-width: 576px) {
      padding-bottom: 24px;
    }
  }

  &__related {
    padding-bottom: 50px;

    &__title {
      margin-top: 78px;
      font-weight: 500;
      margin-bottom: 45px;

      @media screen and (max-width: 790px) {
        margin-top: 47px;
        font-weight: 400;
        margin-bottom: 25px;
      }
    }

    &__items {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      &__item {
        margin-bottom: 20px;
        flex-basis: calc((100% / 2) - 20px);
        transition: all .33s ease;
        opacity: 1;
        @include MQ(M) {
          flex-basis: calc((100% / 4) - 20px);
        }

        > a {
          display: block;
          position: relative;
        }

        &__img {
          max-width: 369px;
          width: 100%;
          margin-bottom: 18px;
          cursor: pointer;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          @media screen and (max-width: 576px) {
            max-width: 146px;
            max-height: 134px;
            margin-right: 25px;
            margin-bottom: 15px;
          }
        }

        &__title {
          font-size: 18px;
          line-height: 33px;
          font-weight: 500;
          text-align: center;
          cursor: pointer;
          transition: all .33s ease;

          @media screen and (max-width: 576px) {
            display: flex;
            align-items: center;
            text-align: left;
            font-size: 16px;
            line-height: 20px;
          }
        }

        &:last-child {
          margin-right: 0;
        }

        &:hover {
          opacity: 1 !important;

          .storeProducts__related__items__item__title {
            color: $orange;
          }
        }
      }

      &:hover {
        > * {
          opacity: .6;
        }
      }
    }
  }

  &--back {
    background-color: #f9fafa;
  }

  @media screen and (max-width: 576px) {
    padding-bottom: 64px;
  }
}

.storeProducts__related {
  h2 {
    color: #0c2e3c;
    @media screen and (max-width: 1180px) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .storeProducts__related__items__item__title {
    color: #0c2e3c;
  }
}

.margin-icon-left {
  margin-left: 12px;
}

.v-toast__item--info {
  background-color: $blue !important;
}
</style>
